import React from 'react';
import { ScrollView, Text, View } from 'react-native';
import { MFullWidthButton, MTextInput } from 'menupass-ui';
import { Container } from '../../components/container';
import { Header } from '../../components/header';
import { Paper } from '../../components/paper';
import { Subheader } from '../../components/subheader';
import { EntitiesPageTitle } from '../../components/entitiespagetitle';
import { PaperColumns } from '../../styles/common/PaperColumns';
import { colors } from '../../consts';
import { PageStyles } from '../../styles/common/PageStyles';
import locale from '../../internationalization';
import { systemConfigStyles } from './SystemConfigPage.styles';
import { SystemConfigPageTypes } from './SystemConfigPage.types';
import { useSystemConfig } from './SystemConfigPage.functions';
import { Routes } from '@/navigation/routes';
import { ErrorHandler } from '../../components/errorhandler';
import { SuccessHandler } from '../../components/successhandler';
import { SystemTabs } from '@/components/systemtabs';
import { Switch } from '@/components/switch/Switch';

const SystemConfigPage = ({
  route: {
    params: { entity },
  },
}: SystemConfigPageTypes) => {
  const {
    entityData,
    adminData,
    systemMinRecurrentPrice,
    systemMinProductQuantity,
    APISuccess,
    apiSuccessMessage,
    loading,
    APIErrorMessage,
    hasAPIError,
    isSchedule,
    schedulePeriodLimit,
    isSectorsOnThePurchaseTotem,
    showAdminClientPurchase,
    discountForEmployeesChildren,
    setDiscountForEmployeesChildren,
    setShowAdminClientPurchase,
    setIsSectorsOnThePurchaseTotem,
    setSchedulePeriodLimit,
    goBack,
    update,
    setSystemMinProductQuantity,
    setSystemMinRecurrentPrice,
    setIsSchedule,
  } = useSystemConfig(entity);

  return (
    <>
      <Header username={adminData?.name} role={adminData?.role} />
      <Subheader
        entityName={entityData?.name}
        entityId={entity}
        activeScreen={Routes.SystemConfig}
        role={adminData?.adminType?.type}
      />
      <Container>
        <EntitiesPageTitle
          title={locale.t('system.title')}
          exportPdf={() => console.log(null)}
          exportCsv={() => console.log(null)}
        />
        <Paper hasSubmenu disableInternalPadding bgColor="transparent">
          <SystemTabs
            entityId={entity}
            system
            periodCreate={false}
            periodList={false}
          />
          <View style={[PaperColumns.tableContainer]}>
            <ScrollView>
              <View style={PaperColumns.container}>
                <View style={PaperColumns.column}>
                  <Text style={PageStyles.subtitle}>
                    {locale.t('system.subtitle')}
                  </Text>
                  <Text style={PageStyles.content}>
                    {locale.t('system.content')}
                  </Text>
                </View>
                <View style={PaperColumns.column}>
                  <View style={PageStyles.subtitleHeader}>
                    <View>
                      {APISuccess && (
                        <SuccessHandler message={apiSuccessMessage} />
                      )}
                    </View>
                    <View style={PageStyles.headerButtons}>
                      <View style={PageStyles.buttonsWrapper}>
                        <MFullWidthButton
                          label={locale.t('clientCreate.cancel')}
                          bgColor={colors.newEntity.entitiesBtn}
                          labelColor={colors.newEntity.entitiesBtnTitle}
                          onPress={goBack}
                          height={42}
                          fontSize={14}
                        />
                      </View>
                      <View style={PageStyles.buttonsWrapper}>
                        <MFullWidthButton
                          label={locale.t('clientCreate.save')}
                          disabled={loading}
                          onPress={update}
                          height={42}
                          fontSize={14}
                        />
                      </View>
                    </View>
                  </View>
                  {hasAPIError && <ErrorHandler message={APIErrorMessage} />}
                  <View>
                    <View>
                      <Text style={systemConfigStyles.subtitle}>
                        {locale.t('system.minimumRecharge')}
                      </Text>
                      <Text style={systemConfigStyles.content}>
                        {locale.t('system.minimumRechargeContent')}
                      </Text>
                      <View style={[PageStyles.inputWrapper, { width: '50%' }]}>
                        <MTextInput
                          label={locale.t('system.form.minimumRecharge')}
                          value={systemMinRecurrentPrice}
                          onChangeValue={setSystemMinRecurrentPrice}
                          isCurrency
                          prefix="R$ "
                          height={42}
                          borderRadius={14}
                        />
                      </View>
                    </View>
                    <View>
                      <Text style={systemConfigStyles.subtitle}>
                        {locale.t('system.minimumStock')}
                      </Text>
                      <Text style={systemConfigStyles.content}>
                        {locale.t('system.minimumStockContent')}
                      </Text>
                      <View style={[PageStyles.inputWrapper, { width: '50%' }]}>
                        <MTextInput
                          label={locale.t('system.form.minimumStock')}
                          value={systemMinProductQuantity}
                          onChangeText={(e: string) =>
                            setSystemMinProductQuantity(
                              e.replace(/[^0-9]/g, ''),
                            )
                          }
                          height={42}
                          borderRadius={14}
                        />
                      </View>
                    </View>
                    <View>
                      <Text style={systemConfigStyles.subtitle}>
                        {locale.t('system.orderSchedule')}
                      </Text>
                      <Text style={systemConfigStyles.content}>
                        {locale.t('system.contentSchedule')}
                      </Text>
                      <View style={[PageStyles.inputWrapper, { width: '50%' }]}>
                        <Switch
                          label={
                            <Text>{locale.t('system.orderSchedule')}</Text>
                          }
                          backgroundInactive={
                            colors.datatableBody.switchDisabled
                          }
                          backgroundActive={colors.datatableBody.switchEnabled}
                          circleColorInactive={colors.datatableBody.thumb}
                          circleColorActive={colors.datatableBody.thumb}
                          onValueChange={setIsSchedule}
                          value={isSchedule}
                        />
                      </View>
                    </View>
                    <View>
                      <Text style={systemConfigStyles.subtitle}>
                        {locale.t('system.orderScheduleTime')}
                      </Text>
                      <View style={[PageStyles.inputWrapper, { width: '50%' }]}>
                        <MTextInput
                          label={locale.t('systemPeriodCreate.timeSchedule')}
                          value={schedulePeriodLimit}
                          onChangeText={(e: string) =>
                            setSchedulePeriodLimit(e.replace(/[^0-9]/g, ''))
                          }
                          height={42}
                          maxLength={2}
                          numeric
                          borderRadius={14}
                        />
                      </View>
                    </View>
                    <View>
                      <Text style={systemConfigStyles.subtitle}>
                        {locale.t('system.displaySectorsOnThePurchaseTotem')}
                      </Text>
                      <Text style={systemConfigStyles.content}>
                        {locale.t('system.contentSectors')}
                      </Text>
                      <View style={[PageStyles.inputWrapper, { width: '50%' }]}>
                        <Switch
                          label={
                            <Text>
                              {locale.t(
                                'system.displaySectorsOnThePurchaseTotem',
                              )}
                            </Text>
                          }
                          backgroundInactive={
                            colors.datatableBody.switchDisabled
                          }
                          backgroundActive={colors.datatableBody.switchEnabled}
                          circleColorInactive={colors.datatableBody.thumb}
                          circleColorActive={colors.datatableBody.thumb}
                          onValueChange={setIsSectorsOnThePurchaseTotem}
                          value={isSectorsOnThePurchaseTotem}
                        />
                      </View>
                    </View>
                    <View>
                      <Text style={systemConfigStyles.subtitle}>
                        {locale.t('system.enableParentPurchase')}
                      </Text>
                      <Text style={systemConfigStyles.content}>
                        {locale.t('system.contentPurchase')}
                      </Text>
                      <View style={[PageStyles.inputWrapper, { width: '50%' }]}>
                        <Switch
                          label={
                            <Text>
                              {locale.t('system.enableParentPurchase')}
                            </Text>
                          }
                          backgroundInactive={
                            colors.datatableBody.switchDisabled
                          }
                          backgroundActive={colors.datatableBody.switchEnabled}
                          circleColorInactive={colors.datatableBody.thumb}
                          circleColorActive={colors.datatableBody.thumb}
                          onValueChange={setShowAdminClientPurchase}
                          value={showAdminClientPurchase}
                        />
                      </View>
                    </View>
                    <View>
                      <Text style={systemConfigStyles.subtitle}>
                        {locale.t('system.discountForEmployeesChildren')}
                      </Text>
                      <Text style={systemConfigStyles.content}>
                        {locale.t('system.contentDiscount')}
                      </Text>
                      <View style={[PageStyles.inputWrapper, { width: '50%' }]}>
                        <Switch
                          label={
                            <Text>
                              {locale.t('system.discountForEmployeesChildren')}
                            </Text>
                          }
                          backgroundInactive={
                            colors.datatableBody.switchDisabled
                          }
                          backgroundActive={colors.datatableBody.switchEnabled}
                          circleColorInactive={colors.datatableBody.thumb}
                          circleColorActive={colors.datatableBody.thumb}
                          onValueChange={setDiscountForEmployeesChildren}
                          value={discountForEmployeesChildren}
                        />
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </ScrollView>
          </View>
        </Paper>
      </Container>
    </>
  );
};

export default SystemConfigPage;
